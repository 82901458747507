@use 'variables';
@use 'classes';
@use 'mixins/content';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  @include content.font-title-large();
  color: variables.$color-text;
}

h2 {
  @include content.font-title-large();
  color: variables.$color-text;
}

h3 {
  @include content.font-title-medium();
  color: variables.$color-text;
}

h4 {
  @include content.font-title-small();
  color: variables.$color-text;
}

h5 {
  @include content.font-title-tiny();
  color: variables.$color-text;
}

p {
  @include content.font-text-medium();
  margin: 16px 0;
  color: variables.$color-text;
}

span {
  @include content.font-text-medium();
  color: variables.$color-text;
}

a {
  @extend .style-link;
}

input {
  &:focus {
    outline: none;
  }
  &[type="checkbox"], &[type="radio"], &[type="submit"], &[type="button"] {
    &:focus {
      outline: auto;
    }
  }
}

textarea {
  &:focus {
    outline: none;
  }
}

hr {
  height: 1px;
  margin: 0px auto;
  border: none;
}