$color-text: #000000;
$color-text-light: #acabb8;
$color-text-medium: #898690;
$color-text-dark: #000000;
$color-text-invert: #FFFFFF;
$color-text-link: #6a97ff;
$color-text-link-hover: #3966cf;

$color-separator: #dedce7;

$color-background: #FFFFFF;
$color-background-light: #f3f1f9;
$color-background-medium: #57555a;
$color-background-dark: #1f1e23;

$color-red: #fa312d;
$color-blue: #5182f2;
$color-green: #75ce6b;
$color-yellow: #FADD50;

$color-red-highlight: #e7201c;
$color-blue-hightlight: #3966cf;

$font-size-huge: 4.5rem;      // 72px
$font-size-extra: 2.3125rem;  // 37px
$font-size-large: 1.5rem;     // 24px
$font-size-medium: 1.25rem;   // 20px
$font-size-normed: 1.125rem;  // 18px
$font-size-normal: 0.9375rem; //-15px-
$font-size-small: 0.8125rem;  // 13px

$font-family-title: Burra, sans-serif;
$font-family-input: Unigeo64, serif;
$font-family-text: Unigeo64, serif;
$font-family-info: Unigeo64, sans-serif;

$layout-nav-width: 176px;
$layout-titlebar-height: 72px;
$layout-scrollarea-margin: 88px;
$layout-scrollarea-margin-small: 44px;

$breakpoint-mobile-small: 480px;
$breakpoint-mobile-medium: 600px;
$breakpoint-mobile: 768px;
$breakpoint-tablet: 900px;
$breakpoint-laptop: 1200px;

// sizes
$selection-image-size-small: 80px;
$selection-image-size-medium: 125px;