@use "../variables";


@mixin font-title-large() {
  font-family: variables.$font-family-title;
  font-weight: 900;
  font-size: variables.$font-size-huge;
  line-height: 5rem;
}

@mixin font-title-medium() {
  font-family: variables.$font-family-title;
  font-weight: 900;
  font-size: variables.$font-size-extra;
  line-height: 2.5rem;
}

@mixin font-title-small() {
  font-family: variables.$font-family-title;
  font-weight: 900;
  font-size: variables.$font-size-large;
  line-height: 2rem;
}

@mixin font-title-tiny() {
  font-family: variables.$font-family-title;
  font-weight: 400;
  font-size: variables.$font-size-medium;
  line-height: 2rem;
}

@mixin font-title-min() {
  font-family: variables.$font-family-title;
  font-weight: 400;
  font-size: variables.$font-size-normed;
  line-height: 1rem;
}



@mixin font-text-extra() {
  font-family: variables.$font-family-text;
  font-weight: 300;
  font-size: variables.$font-size-large;
  line-height: 2rem;

  b {
    font-weight: 700;
  }
}

@mixin font-text-large() {
  font-family: variables.$font-family-text;
  font-weight: 300;
  font-size: variables.$font-size-medium;
  line-height: 1.575rem;

  b {
    font-weight: 700;
  }
}

@mixin font-text-medium() {
  font-family: variables.$font-family-text;
  font-weight: 400;
  font-size: variables.$font-size-normal;
  line-height: 1.425rem;
}

@mixin font-text-small() {
  font-family: variables.$font-family-text;
  font-weight: 400;
  font-size: variables.$font-size-small;
  line-height: 1.3125rem;
}



@mixin font-info-large() {
  font-family: variables.$font-family-info;
  font-weight: 400;
  font-size: variables.$font-size-medium;
  line-height: 1.875rem;
}

@mixin font-info-medium() {
  font-family: variables.$font-family-info;
  font-weight: 400;
  font-size: variables.$font-size-normal;
  line-height: 1.3125rem;
}

@mixin font-info-small() {
  font-family: variables.$font-family-info;
  font-weight: 400;
  font-size: variables.$font-size-small;
  line-height: 1.125rem;
}



@mixin font-input-medium() {
  font-family: variables.$font-family-input;
  font-weight: 400;
  font-size: variables.$font-size-normal;
  line-height: 1.625rem;
}