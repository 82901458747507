@use "variables";


.style {
  &-input {
    font-family: variables.$font-family-input;
    font-size: variables.$font-size-small;
    font-weight: 500;
  }

  &-info {
    font-family: variables.$font-family-info;
    font-size: variables.$font-size-normal;
    font-weight: 400;
    border: none;
    color: variables.$color-text;
  }

  &-link {
    font-family: variables.$font-family-info;
    font-size: variables.$font-size-normal;
    font-weight: 400;
    line-height: 1.5rem;
    color: variables.$color-text-link;
    text-decoration: none;
    transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1);    

    &:hover {
      color: variables.$color-text-link-hover;
    }
  }

  &-tag {
    font-family: variables.$font-family-info;
    font-size: variables.$font-size-normal;
    font-weight: 500;
    border: 2px solid variables.$color-text;
    background: none;
    color: variables.$color-text;
    width: fit-content;
    padding: 0px 10px;
  }
}